import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src020753457/src/braceta-com-website/gatsby-theme-terminal/src/layouts/PageLayout.js";
import { format } from 'date-fns';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Divider = makeShortcode("Divider");
const Flex = makeShortcode("Flex");
const Box = makeShortcode("Box");
const Heading = makeShortcode("Heading");
const Link = makeShortcode("Link");
const SourceTags = makeShortcode("SourceTags");
const Text = makeShortcode("Text");
const SourceList = makeShortcode("SourceList");
const Card = makeShortcode("Card");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Divider mdxType="Divider" />
    <h2>{`About me`}</h2>
    <p>{`Hi, I'm Luís Azevedo a Software Engineer enthusiastic about technology and software development.`}</p>
    <p>{`This is my blog to share some stuff.`}</p>
    {
      /* Stay in [touch](http://blog.braceta.com/contact). Braceta loves you! :P */
    }
    <Divider mdxType="Divider" />
    <Flex sx={{
      flexWrap: "wrap"
    }} mdxType="Flex">  
  <Box sx={{
        width: ["100%", "100%", "55%"],
        mb: 3,
        pr: [0, 0, 4]
      }} mdxType="Box">         
        <h2>{`Contact`}</h2>
  <Heading variant='styles.h3' sx={{
          mb: 0
        }} mdxType="Heading">Twitter</Heading>
  <Link href='https://twitter.com/braceta/' variant='styles.a' mdxType="Link">https://twitter.com/braceta/</Link>
  <Divider mdxType="Divider" />
  <Heading variant='styles.h3' sx={{
          mb: 0
        }} mdxType="Heading">GitHub</Heading>
  <Link href='https://github.com/braceta/' variant='styles.a' mdxType="Link">https://github.com/braceta/</Link>
  <Divider mdxType="Divider" />
  <Heading variant='styles.h3' sx={{
          mb: 0
        }} mdxType="Heading">LinkedIn</Heading>
  <Link href='https://www.linkedin.com/in/lmazevedo/' variant='styles.a' mdxType="Link">https://linkedin.com/in/lmazevedo/</Link>
  <Divider mdxType="Divider" />
  </Box>
  <Box sx={{
        width: ["100%", "100%", "45%"],
        mb: 3
      }} mdxType="Box">
 
        <h2>{`Top 5 tags`}</h2>
  <SourceTags filter="posts" mdxType="SourceTags">
    {tags => <Flex sx={{
            flexDirection: 'column'
          }} mdxType="Flex">
        {tags.splice(0, 5).sort((a, b) => b.percent - a.percent).map((tag, index) => {
              const {
                name,
                count,
                percent
              } = tag;
              return <Box key={index} sx={{
                color: 'muted',
                mb: 3,
                svg: {
                  fill: 'muted'
                }
              }} mdxType="Box">
                <Flex sx={{
                  lineHeight: 'normal'
                }} mdxType="Flex">
                  <Text sx={{
                    color: 'secondary',
                    mr: 2,
                    mb: 0
                  }} mdxType="Text">{`${name}`}</Text>
                  <Text sx={{
                    color: 'muted'
                  }} mdxType="Text">{percent}</Text>
                  <Text sx={{
                    color: 'error'
                  }} mdxType="Text">%</Text>
                </Flex>
                <Flex mdxType="Flex">
                 <Box mdxType="Box">{'['}</Box>
                  <Box sx={{
                    flexBasis: '100%'
                  }} mdxType="Box">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 8" width="100%" height="8">
                      <rect width={`${percent * 3}%`} height={4} />
                    </svg>
                  </Box>
                  <Box mdxType="Box"> {']'}</Box>
                </Flex>
              </Box>;
            })}
      </Flex>}
  </SourceTags>
  </Box>
    </Flex>
    <h2>{`Projects`}</h2>
    <SourceList filter="projects" mdxType="SourceList">
  {projects => <Flex sx={{
        flexWrap: "wrap",
        ml: theme => `-${theme.space[2]}px`,
        mr: theme => `-${theme.space[2]}px`
      }} mdxType="Flex">
      {projects.map((edge, index) => {
          const {
            frontmatter: {
              title,
              date,
              link
            },
            fields: {
              slug
            }
          } = edge.node;
          return <Box key={index} sx={{
            mb: 3,
            maxWidth: ["100%"],
            width: ["100%"]
          }} mdxType="Box">
            <Link href={link || slug} sx={{
              textDecoration: 'none'
            }} target="_blank" rel="noopener" mdxType="Link">
              <Card sx={{
                ml: 2,
                mr: 2,
                p: 3
              }} mdxType="Card"> 
                <Text sx={{
                  fontSize: 0,
                  color: 'muted'
                }} mdxType="Text">{format(new Date(date), 'd-MMM-u')}</Text>
                <Heading variant="styles.h4" sx={{
                  color: 'text'
                }} mdxType="Heading">{title}</Heading>
                <Text sx={{
                  textDecoration: 'underline'
                }} mdxType="Text">View project</Text>
              </Card>
            </Link>
          </Box>;
        })}
    </Flex>}
    </SourceList>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      